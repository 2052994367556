.page-wrap {
  padding: 3rem 0 5rem;

  @media (min-width: $small-size) {
    font-size: 1.1rem;
  }

  @media (min-width: $medium-size) {
    font-size: 1.25rem;
    letter-spacing: -.01em;
    line-height: 1.5;
  }

  h1,
  h2,
  h3 {
    font-family: $font-family-medium;
    letter-spacing: -.03em;
  }

  img {
    max-width: 100%;
  }
}

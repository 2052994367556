$search-results-item-bg: #fff;

.search-results-item {
  background: $search-results-item-bg;
  color: $black;
  display: block;
  margin-bottom: $spacer-md;
  padding: $spacer-md;
  transition: box-shadow $anim-default;

  &:focus,
  &:hover {
    color: $black;
    text-decoration: none;

    .search-results-item__squared-image {
      border-bottom: 5px solid $brand-primary;
    }
  }

  // Only target devices with mouse
  @media (pointer: fine) {
    &:focus,
    &:hover {
      @include shadow(3);
      z-index: 1;
    }
  }

  &__squared-container {
    height: 0;
    line-height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  &__squared-image {
    font-family: 'object-fit: cover;'; // Object fit polyfil
    height: 100%;
    left: 0;
    margin: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__description,
  &__title {
    font-size: inherit;
    margin: 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: normal;
  }

  &__title {
    height: $spacer-sm * 2.5;
  }

  &__description,
  &__period {
    height: $spacer-sm * 2;
  }

  &__period {
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 0;
  }

  svg {
    bottom: 1rem;
    fill: currentcolor;
    height: 3rem;
    position: absolute;
    right: 1rem;
    width: 3rem;
  }
}

.search-results-item__text-content {
  padding-top: $spacer-sm;
}

.search-results-item__type {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: $brand-primary;
  font-weight: 400;
  line-height: 1;
}

.search-results-item__type-icon {
  height: 15px;
}

.search-results-item__type-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: normal;
}

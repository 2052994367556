footer {
  background    : $footer-background-color;
  color         : $footer-text-color;
  font-size     : 90%;
  letter-spacing: .03em;
  padding       : 1.2rem 0;
  margin-top    : auto;
}

.footer__element {
  color  : $footer-text-color;
  display: inline-block;

  &:not(:last-child)::after {
    color  : rgba($footer-text-color, .5);
    content: '\00a0\00a0/\00a0\00a0';
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

$size: .7em;
$small-scale: scaleX(.5) scaleY(.5);
$one-scale: scaleX(1) scaleY(1);

.centered-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .loader-wrapper {
    position: fixed;
    top: \min(200px, 20vh);
    background: white;
    z-index: 10;
    @include shadow(1);
  }
}

.loader-wrapper {
  padding: $size;
  display: none;
  flex-direction: column-reverse;
  text-align: center;

  .loader-set {
    text-align: center;
  }

  .loader {
    animation: loading-dots 1.5s infinite ease-in-out 0s;
    animation-delay: -.4s;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    height: $size;
    margin-left: .5em;
    width: $size;

    &.one {
      animation-delay: -.2s;
    }

    &.two {
      animation-delay: 0s;
      margin-right: .5em;
    }
  }
}

@keyframes loading-dots {
  0% {
    transform: $small-scale;
  }

  35% {
    transform: $one-scale;
  }

  70% {
    transform: $small-scale;
  }

  100% {
    transform: $small-scale;
  }
}

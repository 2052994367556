.twitter-typeahead {
  width: 100%;
  display: block !important; // inline block adds spacing after the span

  .tt-menu {
    @include shadow(2);
    background-color: $white;
    cursor: pointer;
    margin-top: $thick-line - $thin-line;
    padding: .5rem 0 .2rem;

    &:hover {
      .tt-suggestion:hover {
        background-color: $brand-primary;
        color: $white;
      }
    }

    &:not(:hover) {
      .tt-cursor {
        background-color: $brand-primary;
        color: $white;
      }
    }
  }

  .tt-hint {
    bottom: 0;
    color: $gray-light;
    padding-bottom: 0;
    top: auto;
  }

  .tt-suggestion {
    padding: 0 .5rem;
  }

  &.focused {
    ~ .bar::after {
      width: 100%;
    }

    ~ label {
      color: $brand-primary;
    }
  }

  &.focused,
  &.valid {
    ~label {
      font-size: .8rem;
      top: 0;
    }
  }
}

$slider-arrow-size: 4rem;

.related-assets {
  position: relative;

  .related-assets__next-arrow,
  .related-assets__prev-arrow {
    cursor    : pointer;
    margin-top: -$slider-arrow-size / 2;
    opacity   : .2;
    position  : absolute;
    top       : 50%;

    &:hover {
      opacity: .5;
    }

    svg {
      height: $slider-arrow-size;
      width : $slider-arrow-size;
    }
  }

  .related-assets__next-arrow {
    right: -5rem;
  }

  .related-assets__prev-arrow {
    left: -5rem;

    svg {
      transform: rotate(180deg);
    }
  }

  .related-assets__next-arrow,
  .related-assets__prev-arrow {
    display: none;
  }
}

.related-articles {
  max-width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.related-articles__title {
  font-size: 2rem;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 2rem;

  @media (min-width: $medium-size) {
    font-size: 2.62rem;
    margin-bottom: 3rem;
  }
}

.document {
  &__section-1 {
    .is-mobile & {
      padding-top: 0;
    }
  }
  &__section-2 {
    &__no-placement {
      float: right;
    }
    &--map {
      background: none;
      position: relative;
      &.no-location {
        display: none;
      }
    }
  }
  &__section-3 {
    a {
      color: $brand-primary-darker;
    }
  }
  &__expandable--expanded {
    [class*='col-'] {
      width: 100%;
      left: 0;
    }
  }
  &__navigator {
    &-arrow {
      top: 100px;
    }
  }
  &__player-controls {
    .is-mobile & {
      text-align: left;
      font-family: $font-family-medium;
    }
    &__actions {
      .is-mobile & {
        float: left;
      }
    }
    .zoom-in {
      display: inline-block;
    }
    .zoom-out {
      display: none;
    }
    &__rotate {
      &--item {
        float: left;
        display: none;
        &--active {
          display: block;
        }
      }
      svg {
        transform: rotate(280deg);
        float: left;
      }
    }
    &--social-share {
      float: right;
      &:first-of-type {
        margin-left: 12px;
      }
    }
    a {
      span {
        margin-left: 5px;
        color: $gray-darker;
        font-weight: bold;
        .document__expandable--expanded & {
          .zoom-in {
            display: none;
          }
        }
      }
    }
  }
  &__back-link {
    a,
    button {
      color: $brand-primary;
      font-weight: bold;
      border: none;
      background: transparent;
      text-decoration: underline;
      margin: 0;
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      &:hover {
        color: $link-hover-color;
      }

      @media(max-width: $small-size) {
        margin: 2rem 0;
        font-family: $font-family-medium;
      }
    }
    svg {
      height: 25px;
      width: 25px;
      fill: $brand-primary;
      position: relative;
      margin-right: 5px;
    }
  }
  .no-location--text {
    &__header {
      font-size: 15px;
    }
  }

  // Have any controls on the asset-page that allows the user to toggle zoom
  // show a zoom cursor.
  &__expandable {
    [data-action='toggle-tiled-zoom'] {
      cursor: zoom-in;
    }
  }
}

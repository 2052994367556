@mixin search-freetext-form(
  $detail-color,
  $text-color: $detail-color,
  $placeholder-color: $text-color,
  $detail-color--dimmed: rgba($detail-color, .66)
) {
  .search-freetext-form {
    &__input {
      color: $text-color;

      &::placeholder {
        color: $placeholder-color;
      }
    }

    &__btn {
      color: $detail-color;
    }
  }

  .jumbo & {
    .input-group {
      &::after {
        background-color: $detail-color--dimmed;
      }
    }
  }
}

.search-freetext-form {
  flex-grow: 1;
  margin: 0 10px;
  position: relative;

  .input-group {
    max-width: 20rem;

    &::after {
      bottom: .2rem;
      content: '';
      height: .1rem;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  &__input,
  &__btn {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &__input {
    height: $input-height-base + 2;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-top: 7px;

    &::placeholder {
      font-family: $font-family-sans-serif;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  &__btn {
    height: auto;
    line-height: 1.2;
    margin-left: 0;
    padding-right: 0;

    &:active {
      box-shadow: none;
    }
  }

  &--large {
    .input-group {
      &::after {
        height: .15rem;
      }
    }

    .search-freetext-form {
      &__input {
        font-size: 1.6rem;
        height: 3rem;
      }

      &__btn {
        svg {
          height: 2rem;
          margin-bottom: .2rem;
          width: 2rem;
        }
      }
    }
  }

  &--on-brand {
    @include search-freetext-form($white);
  }

  &--on-white {
    @include search-freetext-form($brand-primary, $black, $gray-dark);
  }

  &--on-dark {
    @include search-freetext-form($white, $white, $gray-light, $gray-light);
  }

  &--focus {
    &.search-freetext-form {
      &--on-dark {
        @include search-freetext-form($white, $white, $gray-light, $white);
      }
    }
    // TODO: Add something that changes the color on the line when focussed
  }
}

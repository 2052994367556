// Styling shared between the live site and the map documentation.
@import "map-shared";

// Override map assets.
#mapPopupClose {
  content: url('/images/icons/map/close.png');
}
.ol-control select {
  background-image: url('/images/icons/map/downarrow.png');
}
#timeWarpClose {
  background-image: url('/images/icons/map/timewarpclose.png');
}
#timeWarpMode {
  background-image: url('/images/icons/map/timewarpsplit.png');
}
.map.split #timeWarpMode {
  background-image: url('/images/icons/map/timewarpcircle.png');
}
#mapLocation {
  background-image: url('/images/icons/map/location.png');
}
.map.location #mapLocation {
  background-image: url('/images/icons/map/locationwhite.png');
}
#timeWarpToggle {
  background-image: url('/images/icons/map/maptoggle.png');
}
.map.time-warp #timeWarpToggle {
  background-image: url('/images/icons/map/maptogglewhite.png');
}

// instruction box
.map-instruction-box {
  background: white;
  padding: 10px;
  font-size: 0.9em;
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  z-index: 1;

  .is-mobile :not(.search-results) & {
    position: static;
  }

  .search-results & {
    top: 0;
    right: 0;
    left: calc(100% - 200px);

    .is-mobile & {
      display: none;
    }
  }
}

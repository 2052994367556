.footer {
  padding: 2.2rem 0 2rem;
  font-size: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  position: relative;
  z-index: 1;

  &__element:not(:last-child)::after {
    display: none; // override collections online default
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__address {
    margin-bottom: 0;
  }

  &__logo {
    line-height: 1;
    margin-top: -.4rem;

    // 6162 8862  ,695328368
    $iconHeight: 8.4rem;
    $iconHeightResponsive: 25vw;
    $iconRatio: .695;
    svg {
      fill: $white;
      height: $iconHeight;
      max-height: $iconHeightResponsive;
      max-width: $iconHeightResponsive * $iconRatio;
      width: $iconHeight * $iconRatio;
    }
  }

  &__menu {
    font-family: $font-family-medium;
    letter-spacing: .04em;

    @media (max-width: $small-size) {
      padding-bottom: 2rem;
    }
  }
  .is-tiled-zoom & {
    display: none;
  }
}

@import 'functions-shadows';

.dropdown {
  display    : inline-block;
  position   : relative;
  user-select: none;
}

.dropdown--active {
  .dropdown__options {
    @include shadow(1);
    opacity   : 1;
    visibility: visible;
  }

  .dropdown__selected {
    border-bottom-color: transparent;
    position           : relative;
    background-color: transparent;

    &:after {
      transform: rotate(180deg);
    }
  }
}

.dropdown__selected {
  color      : $text-color;
  display    : inline-block;
  font-weight: bold;
  padding    : 0 1.4em 0 0;

  &:focus,
  &:hover {
    color          : $text-color;
    text-decoration: none;
  }

  &::after {
    border-left : .4em solid transparent;
    border-right: .4em solid transparent;
    border-top  : .4em solid $text-color;
    bottom      : .1em;
    content     : '';
    height      : 0;
    margin      : auto;
    position    : absolute;
    right       : .4em;
    top         : 0;
    width       : 0;
  }
}

.dropdown__selected--not-bold {
  font-weight: normal;
}

.dropdown__options {
  @include shadow(0);
  background: $dropdown-bg;
  right: 0;
  margin-top: $dropdown-padding / 2;
  max-width : calc(100vw - #{$dropdown-padding * 2});
  opacity   : 0;
  padding   : $dropdown-padding 0;
  position  : absolute;
  text-align: left;
  transition: $anim-duration-fast ease;
  visibility: hidden;
  z-index   : 10;
}

.dropdown__option {
  color        : $text-color;
  display      : block;
  line-height  : 2.5;
  overflow-x   : hidden;
  padding      : 0 $dropdown-padding * 2;
  text-overflow: ellipsis;
  white-space  : nowrap;
  width        : 100%;

  &:focus,
  &:hover {
    background     : $dropdown-bg-hover;
    color          : $text-color;
  }
}

.dropdown__option--active {
  font-weight: bold;
}

.search-results-header {
  .dropdown__selected {
    border-bottom: 1px solid $text-color;
    margin-bottom: -1px;
    padding: 0 1.6em 0 .5em;
  }

  .dropdown--active {
    .dropdown__selected {
      border-bottom: 1px solid $black;
    }
  }

  .dropdown__options {
    left: auto;
    right: -$dropdown-padding;
  }
}

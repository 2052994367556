.btn {
  &:focus {
    outline: 1px dotted black;
  }

  &-dark {
    background-color: $gray-dark;
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($gray-dark, 10%);
      color: $white;
    }
  }
}

.btn-close {
  padding-right: $spacer-xs;
  position: relative;
}

.btn:not(.search-freetext-form__btn):not(.btn-no-shadow) {
  @include shadow(1);

  @media (pointer:fine) {
    &:hover {
      @include shadow(2);
      transform: translateY(-1px);
      z-index: 1;
    }

    &:focus {
      outline: $white solid 1px;
    }

    transition: $anim-default;
  }
}

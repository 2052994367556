.search-freetext-form {
  margin: 0;
  .is-mobile & {
    width: 100%;
  }
  &--jumbo {
    .search-freetext-form {
      &__input {
        background: white;

        &::placeholder {
          color: $gray-dark;
        }
      }
    }
  }
}
// Colors
$black: #000;
$white: #fff;
$gray-darker: #404040;
$gray-dark: #555;
$gray: #c0c0c0;
$gray-light: #e0e0e0;
$gray-lighter: #f0f0f0;
$brand-primary: #E01962;
$brand-secondary: #A81349;
$brand-primary-darker: #BF1554;
$text-color: $gray-dark;
$input-color: $gray-dark;

// Typography
$font-family-thin: 'FFUnitWebThin', sans-serif;
$font-family-light: 'FFUnitWebLight', sans-serif;
$font-family-medium: 'FFUnitWebMedium', sans-serif;
$font-family-sans-serif: $font-family-light;
$headings-font-weight: normal;
$input-border-focus: $brand-primary;

// Menu
$menu-width: 200px;
$menu-width-small: 200px;
$topbar-height: 50px;

// Documents
$document-heading-font: $font-family-medium;

// Jumbo (frontpage)
$logo-height: 4rem;
$jumbo-bg: $black;
$jumbo-text-shadow: $black;
// Andreas suggestion
// $logo-height: 3rem;
// $jumbo-bg: hsl(350, 100%, 25%);
// $jumbo-text-shadow: hsl(346, 100%, 15%);

$document-section-backgrounds: (
  1: mix($white, $gray, 100%),
  2: mix($white, $gray, 75%),
  3: mix($white, $gray, 50%),
  4: mix($white, $gray, 25%),
  5: mix($white, $gray, 0%)
) !default;

// Loading variables again at the bottom allows default
@import './collections-online/variables';

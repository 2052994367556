.gallery {
  &__container {
    margin-bottom: $spacer-lg;
    z-index: 1;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &--carousel {
    position: relative;
    .gallery {
      &__title {
        float: left;
        margin-right: 6px;
        .is-mobile & {
          font-size: 1.4rem;
        }
      }
      &__subtitle {
        .is-mobile & {
          font-size: 1.2rem;
          margin-top: 25px;
        }
      }
    }
  }
  &--block {
    text-align: left;
    .is-mobile & {
      margin-top: $spacer-lg;
    }
    a {
      text-align: center;
      padding: 50px 15px;
      transition: box-shadow 0.2s, background-color 0.2s;
      .is-mobile & {
        padding: 20px 0;
      }
      .is-desktop & {
        &:hover {
          background: white;
          text-decoration: none;
          @include shadow(1);
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .gallery {
      &-thumb {
        width: 100%;
        height: 100%;
        svg {
          fill: white;
        }
        &__title {
          margin: 1.5rem 0;
          .is-mobile & {
            font-size: 1.3rem;
          }
        }
        &__text {
          line-height: 2rem;
          .is-mobile & {
            line-height: 1.5rem;
          }
        }
      }
      &__items {
        display: inline-block;
      }
    }
  }
  &--map {
    .btn.btn-big.btn-primary {
      margin-top: 2rem;
    }
  }
  &--tags {
    .tag-cloud {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 2rem 0 0;

      @media (min-width: $small-size) and (orientation: landscape) {
        margin: 0;
      }

      .btn {
        box-shadow: none;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  &__title {
    font-size: 1.75rem;
    font-weight: bold;
    color: $black;
    margin: 1.5rem 0;
  }
  &__text, &__description {
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: bold;
  }
  &-thumb {
    float: left;
    margin: 0;
    &__text {
      display: block;
    }
    &__overlay {
      background: none;
      color: $gray-darker;
      padding: 0;
      width: auto;
      max-width: 230px;
      position: static;
    }
  }
  &--odd {
    flex-direction: row;
  }
  &--even {
    background: $gray-lighter;
  }
}
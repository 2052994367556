.search-results-item {
  &__title {
    font-size: 120%;
    font-weight: 600;
  }

  &--black {
    background: black;
    color: white;
    border: 15px solid white;

    &:hover, &:focus {
      color: white;
    }
  }
}

.search-results-first-series {
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 1em 1em 2em;
  z-index: 1;

  /* align first series result box with images in other results */
  margin-top: 15px;
  border-right: 15px solid white;

  a:not(.tag) {
    color: inherit;
    text-decoration: inherit;
  }

  &:hover {
    color: white;
    text-decoration: inherit;
  }
}

.search-results-first-series__title {
  margin-bottom: 11px;

  h2 {
    font-family: FFUnitWebMedium,sans-serif;
    margin: 0;
  }
}

.search-results-first-series__description {
  display: block;
}

.motif-tagging {
  .print {
    display: none;
  }

  @media print {
    &__remove-btn,
    &__editing-container,
    .non-print {
      display: none;
    }
    .print {
      display: block;
    }
  }
  &__no-tags {
    width: 100%;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spacer-md;
    .motif-tagging__remove-btn {
      float: right;
    }
  }

  &__edit-btn {
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.categories-menu {
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: $font-family-medium;
  letter-spacing: .04em;
}

.categories-menu-open {
  .dropdown-menu-right {
    left: 0;
    right: auto;
    transition: left $anim-entrance $anim-duration;
  }
}

.dropdown-menu-right {
  left: -$menu-width;
  right: auto;
  transition: left $anim-exit $anim-duration;
  width: $menu-width;

  a {
    width: 100%;
  }

  @media (min-width: $small-size) {
    left: -$menu-width-small;
    width: $menu-width-small;
  }
}

.hamburger {
  padding: 0;
}

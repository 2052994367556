.film,
.billede {
  display: block;
  margin: .5rem 0 1rem;
}

.film {
  height: 0;
  padding-bottom: 56.25%;

  &.fuld-bredde {
    padding-bottom: 56.25vw;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.billede {
  max-width: 100%;

  &.fuld-bredde {
    max-width: 100vw;
  }
}

.fuld-bredde {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

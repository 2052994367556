$heading-adjustment: 2px;

.filterbar {
  background: $gray-light;
  display: block;
  height: $topbar-height;
  float: right;
  width: 100%;
  padding: 0 $spacer-layout;
  position: relative;
  z-index: 2;
  @media (min-width: $extra-large-size) {
    padding: 0 20px;
  }
  .is-mobile & {
    display: none;
  }
  &--mobile {
    padding: 0;
    // Don't display the mobile variant on desktop.
    .is-desktop & {
      display: none;
    }
    // Mobile map view.
    .is-map-view & {
      &__button {
        &--map {
          display: none;
        }
        &--list {
          display: block;
        }
      }
    }
    // Mobile list view.
    .is-list-view & {
      &__button {
        &--map {
          display: block;
        }
        &--list {
          display: none;
        }
      }
    }
    // Mobile filter/view buttons.
    position: fixed;
    bottom: 18px;
    width: 100%;
    z-index: 4;
    margin: 0 auto;
    height: 0;
    display: flex;
    justify-content: center;

    &__container {
      background: white;
      position: relative;
      bottom: 48px;
      border-radius: 3px;
      height: 48px;
      width: 214px;
      transition: bottom .2s;
      @include shadow(3);
      // Do not show standard button container on filter view.
      .has-filter-open & {
        display: none;
      }

      .is-map-view & {
        bottom: 120px;
      }
      // Only show close button on filter view.
      &--close {
        .has-filter-open & {
          display: inline-block;
          position: absolute;
          bottom: 10px;
        }
        display: none;
      }
    }
    &__button {
      cursor: pointer;
      display: inline-block;
      float: left;
      &:first-of-type {
        border-right: 1px solid rgba($color: #000000, $alpha: 0.10);
      }
      &--close {
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        background: white;
        padding: 0;
        border: 0;
        @include shadow(3);
      }
      // Half width of container.
      min-width: 107px;
      svg {
        width: 24px;
        height: 24px;
        float: left;
        margin: 12px;
      }
    }
    .search-filter-sidebar__tab {
      height: $topbar-height;
      line-height: $topbar-height;
      padding: 0;
    }
  }
  .is-map-view &,
  .is-list-view & {
    transition: width 500ms ease-in;
  }
  .is-list-view & {
    width: 100%;
  }
  &__menu {
    background: white;
    position: absolute;
    right: 0;
    left: 0;
    top: $topbar-height;
    display: none;
    width: auto;
    min-width: 300px;
    z-index: 10;
    @include shadow(3);
    .dropdown {
      padding: 10px;
      &__options {
        position: static;
        opacity: 1;
        visibility: visible;
        margin-top: 0;
      }
      &__option {
        &--active {
          color: $white;
          background-color: $brand-primary;
          text-decoration: underline;
        }
      }
    }
  }
  &__item {
    float: left;
    position: relative;
    margin-right: $spacer-md;
  }
  &__tab {
    position: relative;
    z-index: 11;
    display: inline-block;
    padding: 0;
    background: none;
    &.expanded {
      background: white;
    }
    &--sorting {
      .is-map-view & {
        display: none;
      }
    }
  }
  .view-mode {
    float: right;
    svg {
      width: 18px;
      height: 18px;
      margin-top: 15px;
    }
    .filterbar__item {
      height: $topbar-height;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
        float: right;
      }
    }
    .filterbar__tab {
      .is-map-view & {
        &--map {
          height: $topbar-height;
          border-bottom: 3px solid $brand-primary;
        }
      }
      .is-list-view & {
        &--list {
          height: $topbar-height;
          border-bottom: 3px solid $brand-primary;
        }
      }
      &:last-child {
        min-width: 0;
      }
    }
    .filterbar__tab {
      height: $topbar-height;
      &--active {
        height: $topbar-height;
        border-bottom: 3px solid $brand-primary;
      }
    }
    .search-filter-sidebar__heading {
      padding-left: 0;
      span {
        height: $topbar-height;
      }
    }
  }
}

.filterbar__filter-text {
  display: flex;
  align-items: center;
  line-height: 50px;
  gap: 1rem;
}

.filter__clear-filters-btn {
  color: $brand-primary;
}

.search-filter-sidebar {
  background: white;
  margin: 0;

  @media (min-width: $small-size) {
    margin: 0;
  }

  &__heading {
    background: white;
    font-family: $font-family-medium;
    font-size: 1rem;
    line-height: $topbar-height;
    margin: 0;
    padding-left: $spacer-sm + $spacer-xs;
    padding-right: $spacer-sm + $spacer-xs;

    @media (min-width: $small-size) {
      font-size: 1.285714286rem; // 18/14
      padding-bottom: -$heading-adjustment;
      padding-left: $spacer-md;
      padding-right: $spacer-md;
    }

    .is-map-view & {
      &--location-filters {
        display: none;
      }
    }

    &::first-letter {
      text-transform: uppercase;
    }

    // Used for mobile accordion navigation
    span,
    button {
      cursor: pointer;
      display: inline-block;
      padding-right: $spacer-lg;
      position: relative;
      width: 100%;

      &::first-letter {
        text-transform: uppercase;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.6 8.6L12 13.2 7.4 8.6 6 10l6 6 6-6z' fill='rgba(0,0,0,0.6)'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: $spacer-lg;
        bottom: $spacer-xs / 2;
        content: '';
        position: absolute;
        right: - $spacer-xs;
        top: 0;
        transition: .3s ease;
        width: $spacer-lg;
      }

      &.expanded::after {
        transform: rotate(180deg);
      }

      &:not(:last-child) {
        margin-right: $spacer-sm;
      }
    }
  }

  &__tab {
    float: left;
    background: none;
    border: 0;

    &:focus {
      outline: 1px dotted black;
    }
  }

  &__dates {
    margin: $spacer-xs;

    input {
      margin-bottom: $spacer-sm;
    }
  }

  &__filters {
    display: none;

    &--expanded {
      display: block;
    }
  }

  &__filters,
  &__chosen-filters {
    background-color: rgba(white, .5);
    padding: $spacer-sm;

    .search-filter-sidebar__heading {
      margin-left: $spacer-xs;
      padding-left: 0;
      .has-filter-open & {
        margin-left: 0;
      }
    }

    @media (min-width: $small-size) {
      padding: $spacer-xs;
      padding-bottom: $spacer-sm;
    }
  }

  .filter-text {
    line-height: 1.4;
    margin: $spacer-xs;
  }

  &__filter-btn {
    margin: $spacer-xs;
    // expected max-width: 100%; would work, but margin gives issues.
    max-width: 90%;
    max-width: calc(100% - #{$spacer-xs * 2});

    // See http://stackoverflow.com/questions/35464067/flexbox-not-working-on-button-element-in-some-browsers
    &-container {
      display: flex;
      flex-wrap: nowrap;
    }

    @if $filter-btn-full-width == true {
      justify-content: space-between;
      width: 100%;

      &-text {
        flex-grow: 1;
        text-align: left;
      }
    }

    &-count,
    &-text,
    svg {
      align-self: center;
    }

    &-count {
      font-family: $font-family-light;
      font-weight: 300;
      padding-left: $spacer-xs;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      flex-shrink: 0;
      margin: 0 $filter-btn-svg-right-margin 0 .5em;
    }
  }
}

.active-filters-indicator {
  &.search-filter-sidebar__tab {
    padding-right: 45px !important;
  }

  &::before {
    content: '✓';
    font-size: 10px;
    font-weight: 700;
    color: $white;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    line-height: 1;
    background-color: $brand-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.filterbar--mobile__button {
    position: relative;

    &::before {
      right: 8px;
    }
  }
}

.overlay__container {
  background: rgba($topbar-color, .9);
  bottom    : 0;
  left      : 0;
  opacity   : 0;
  position  : fixed;
  right     : 0;
  top       : 0;
  transition: opacity $anim-duration $anim-exit;
  visibility: hidden;
  z-index   : 90000;

  &--active {
    visibility: visible;
  }

  &--anim-in {
    opacity                   : 1;
    transition-timing-function: $anim-entrance;

    .overlay__content {
      transform                 : translate(-50%, -50%) scale(1);
      transition-timing-function: $anim-entrance;
    }
  }

  .overlay__hide {
    position: absolute;
    right   : 2rem;
    top     : 2rem;

    svg {
      cursor: pointer;
      fill  : $white;
      height: 3rem;
      width : 3rem;
    }
  }
}

.overlay__content {
  color     : $white;
  left      : 50%;
  position  : absolute;
  top       : 50%;
  transform : translate(-50%, -50%) scale(.8);
  transition: transform $anim-duration $anim-exit;

  > * {
    display     : block;
    margin-left : auto;
    margin-right: auto;
    max-width   : 25rem;
  }

  .btn {
    margin-bottom: .5rem;
    text-align   : center;
    width        : 100%;
  }

  .overlay__license {
    img {
      height: 2.5rem;
    }
  }
}

.btn-group {
  margin-right: .5rem;
}

.btn {
  border: 0;

  svg {
    fill: currentColor;
    height: 18px;
    margin: 0 .2em;
    pointer-events: none;
    vertical-align: middle;
    width: 18px;
  }

  &-default {
    background-color: $button-bg;
    color: $black;

    &:hover,
    &:focus {
      background-color: darken($button-bg, 10%);
    }
  }

  &-primary {
    background-color: $brand-primary;
    color: $white;

    &:hover,
    &:focus {
      background-color: lighten($brand-primary, 5%);
    }
  }

  &-transparent {
    background-color: transparent;
  }

  &-icon {
    padding: .2rem;
    vertical-align: middle;
  }

  &-small {
    font-size: 90%;
    padding: .32rem .5rem .2rem;

    &.btn-icon {
      padding: .22rem;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }

  &-big {
    padding: 10px 20px;
    border-radius: 3px;
  }
}

.jumbo {
  background-color: $jumbo-bg;
  height: calc(85vh + 300px);
  min-height: calc(37rem + 300px);
  position: relative;
  align-items: center;
  margin-bottom: -300px;
  overflow: hidden;

  @media (min-width: $small-size) and (orientation: landscape) {
    height: calc(45vh + 300px);
  }

  @media (min-width: $medium-size) and (orientation: landscape) {
    height: calc(50vh + 300px);
  }

  @media (min-width: $large-size) and (orientation: landscape) {
    height: calc(75vh + 300px);
  }

  .clear {
    clear: both;
    float: none;
  }

  .container-fluid {
    height: 80%;
  }

  &__flex,
  &__logos {
    position: relative;
  }

  &__flex {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 2rem;

    .container-fluid {
      max-width: $large-size;
      width: 100%;
    }
  }

  &__logos {
    display: flex;
    justify-content: left;
    padding-top: 1rem;
    padding-bottom: 4rem;
    z-index: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: left;

    .is-mobile & {
      a {
        float: left;
        width: 50%;
        padding: 0 20px;
        margin-bottom: 20px;
      }
    }

    .kbh-museum-logo {
      // Align logo with search field.
      .is-desktop & {
        margin-left: -42px;
      }
      svg {
        margin-left: -22px;
      }
    }

    a:hover {
      svg {
        filter: drop-shadow( -2.5px -2.5px 2.5px #000 );
      }
    }

    svg {
      fill: $white;
      fill-rule: evenodd;
      height: $logo-height * 0.8;
      margin: 0 15px;
      width: $logo-height * 4 * 0.8;
      &:first-child {
        .is-desktop & {
          margin-left: 0;
        }
      }

      @media (min-width: $medium-size) {
        margin: 0 50px;
      }

      .is-mobile & {
        width: 100%;
        margin: 0;
      }
    }
  }

  .jumbo__logo {
    padding-bottom: 2rem;
  }

  &__teaser {
    color: $white;
    // font-family: $font-family-medium;
    font-size: 2.2rem;
    letter-spacing: -.01em;
    line-height: 1.2;
    margin-bottom: 40px;
    margin-top: 4rem;
    text-shadow:
      0 0 1px rgba($jumbo-text-shadow, .6),
      0 0 4px rgba($jumbo-text-shadow, .2);

    @media (min-width: $medium-size) {
      font-size: 2.6rem;
    }

    @media (min-width: $large-size) {
      font-size: 3rem;
    }
  }

  &__teaser-text {
    font-size: 1em;
  }

  &__image {
    background-image: url('../images/frontpagetop.jpg');
    background-position: center calc(50% - 150px);
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: .3;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__search {
    margin-top: 40px;
    margin-bottom: 20px;

    .search-freetext-form {
      flex-grow: 0;
      width: 100%;

      .sr-only {
        position: static;
        margin-right: 5px;
      }

      .input-group-btn {
        background: $brand-primary;
        color: white;
        border-radius: 3px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        width: 100px;
        text-align: center;
      }

      .input-group {
        max-width: none;
        width: 100%;
      }

      &__input {
        font-weight: bold;
        font-family: $font-family-medium;
        padding-bottom: .3rem;
        border-radius: 2px;
        background: white;
        padding: 15px;
        height: 60px;
      }
    }
  }

  &__link-section {
    text-align: right;
    margin-top: 20px;
  }

  &__show-all-assets {
    padding: 6px;
    color: $white;
  }

  &__inner {
    bottom: calc(4rem + 300px);
    position: absolute;
    width: 100%;
    left: 0;

    @media (min-width: $small-size) and (orientation: landscape) {
      left: auto;
    }
  }

  &:after {
    content: '';
    height: 700px;
    width: 100%;
    position: absolute;
    background-color: white;
    bottom: -310px;
    transform: skewY(-5.5deg);
  }
}

.frontpage-galleries {
  padding-bottom: 0;
}

// Antialiasing fix (remember to turn of for white text on dark bg)

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
  -webkit-font-smoothing: subpixel-antialiased;
}

::selection {
  color: $white;
  background: $brand-primary;
}

h1 {
  font-size: 2em;
  line-height: 1.25;
}

.lead,
h2 {
  font-size: 1.42em;
  margin-bottom: .3rem;
}

h1 {
  letter-spacing: -.03em;
}

pre {
  font-size: .8em;
}

p {
  margin-bottom: 1rem;
}

@media (min-width: $medium-size) {
  html, body {
    font-size: 15px;
  }

  h1 {
    font-size: 2.62em;
    margin-left: -1px;
  }

  .lead,
  h2 {
    font-size: 1.62em;
  }
}

b,
optgroup,
strong,
.h4 {
  font-family: $font-family-medium;
  font-weight: $headings-font-weight;
}

.btn,
.dropdown__option--active,
.dropdown__selected,
dt,
label,
th {
  font-family: $font-family-medium;
  font-weight: $headings-font-weight;
}

// Material style inputs
$input-animation: .2s ease all;
$thin-line: 1px;
$thick-line: 2px;

.material-input {
  margin-bottom: .8rem;
  margin-top: 1rem;
  max-width: 300px;
  position: relative;
  width: 100%;

  input {
    background: transparent;
    border: 0;
    border-bottom: $thin-line solid $text-color;
    box-shadow: none;
    display: block;
    padding-bottom: $thick-line;
    transition: $input-animation;
    width: 100%;
  }

  label {
    color: $gray-light;
    font-weight: 300;
    pointer-events: none;
    position: absolute;
    top: .2rem;
    transition: $input-animation;
  }

  .bar {
    display: block;
    position: relative;

    &::after {
      background: $brand-primary;
      content: '';
      height: $thick-line;
      left: 50%;
      position: absolute;
      top: - $thick-line;
      transition: $input-animation;
      width: 0;
    }
  }

  // When the input is focussed
  .twitter-typeahead.focused,
  input:focus {
    ~ .bar::after {
      left: 0;
      width: 100%;
    }

    ~ label {
      color: $brand-primary;
    }
  }

  .twitter-typeahead.focused,
  .twitter-typeahead.valid,
  input:focus {
    ~ label {
      font-size: .8rem;
      top: -1rem;
    }
  }
}

.search-results {
  .container-fluid {
    clear: both;
  }

  &--loading,
  [aria-busy] {
    .loader-wrapper {
      display: flex;
    }

    .search-results-header,
    .search-results-item {
      opacity: .75;
    }

    #no-results-text {
      display: none;
    }
  }
}

.search-results-header {
  align-items: baseline;
  display: inline-block;
  margin-bottom: $spacer-md;
  margin-top: $spacer-lg + $spacer-md;

  &__sorting {
    flex-shrink: 0;
    margin-left: auto;
    padding: $spacer-sm 0 0 $spacer-md;
  }

  &__title {
    margin: 0;

    &--loaded {
      display: inline-block;
    }
  }
}

// Load more button
.load-more {
  display: block;
  margin: 0 auto 4.2rem;
  width: 12rem;
}

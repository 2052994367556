.list-view {
  width: 100%;
  // Add 10 to the spacer layout in order
  // to align with the buttons padding.
    padding: 0 $spacer-layout + 10;
    @media (min-width: $extra-large-size) {
      padding: 0 $spacer-layout-large + 10;
    }
  @include shadow(3);
  // Show when in list view mode.
  .is-list-view & {
    display: block;
  }
  .is-map-view & {
    display: none;
  }
  // Mobile styling.
  .has-filter-open & {
    display: none;
  }
  .is-mobile & {
    padding: 0 $spacer-lg;
    width: 100%;
    min-height: 100vh;
    top: 40px;
    background: white;
    overflow: visible;
    .load-more {
      display: inline-block;
      margin-bottom: 100px;
      width: 100%;
    }
  }
  #filters {
    display: none;
  }

  .is-desktop & {
    min-height: calc(100vh - 186px - 50px);
  }
}

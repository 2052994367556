$map-background: #eaeaea;

.geo-tagging {
  display: flex;
  flex-direction: row;

  @media (max-width: $small-size) {
    flex-direction: column;
  }

  &__map {
    background: $map-background;
    display: none;
    position: relative;

    &-element {
      height: 0;
      width: 100%;

      * {
        font-family: $font-family-sans-serif !important;
      }

      .controls {
        border: 1px solid transparent;
        border-radius: 0;
        box-shadow: 0 2px 6px rgba($black, .3);
        box-sizing: border-box;
        height: 32px;
        margin-top: 10px;
      }

      input {
        background-color: $white;
        color: $text-color;
        font-size: $font-size-base;
        font-weight: 300;
        margin-left: 12px;
        max-width: 50%;
        padding: 0 11px;
        text-overflow: ellipsis;
        width: 300px;

        &:focus {
          border-color: $brand-primary;
        }
      }
    }
  }

  &__overlay {
    background-color: rgba($topbar-color, .9);
    bottom: 0;
    color: $white;
    display: none;
    left: 0;
    padding: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateZ(3px);
    z-index: 30;

    &--visible {
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity: 1;
      visibility: visible;
    }

    h2 {
      margin: 0 0 10px;
    }

    .btn {
      width: 100%;
    }
  }

  &__map-buttons {
    bottom: 19px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    transform: translateZ(2px);
    width: 100%;
    z-index: 20;

    &.back-to-map {
      bottom: auto;
      left: 5px;
      top: 10px;
      width: auto;
    }

    &.btn,
    .btn {
      box-shadow: 0 2px 6px rgba($black, .3);
      margin: 0 5px;
      pointer-events: auto;
    }
  }

  &--visible {
    .geo-tagging {
      &__map {
        display: block;
      }

      // On larger screens - put these side-by-side
      @media (min-width: $small-size) {
        &__player,
        &__map {
          width: 50%;
        }
      }
    }
  }
}

.geo-tagging-mini-map {
  position: relative;
  background: $map-background;
  height: 10rem;
  margin-top: .5rem;
  width: 100%;
}

.geo-tagging-mini-map__overlay {
  display: none;
  padding: 18px;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.1);
  background: linear-gradient(rgba(85, 85, 85, 0.1) 11.4%, rgba(85, 85, 85, 0.5) 70.2%);

  // Only show overlay if a map has been loaded
  .map & {
    display: flex;

    @media (min-width: $small-size) {
      display: none !important;
    }

    &.geo-tagging-mini-map__overlay--hide {
      display: none;
    }
  }
  // Do not show overlay in map-view mode (fullscreen)
  .map-view .map & {
    display: none !important;
  }
}

.geo-tagging-mini-map__controls {
  display: block;

  @media (min-width: $small-size) {
    display: none !important;
  }

  // Always show overlay in map-view mode (fullscreen)
  .map-view .map & {
    display: block;
  }
}

.geo-tagging-mini-map__controls--hide {
  display: none;
}

.geo-tagging-mini-map__navigation {
  position: absolute;
  top: 100px;
  left: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  z-index: 1;

  // Tweak navigation position in map-view mode (fullscreen)
  .map-view .map & {
    top: 152px;
  }
}

.geo-tagging-mini-map__navigation-button {
  background-color: #fff;
}

.geo-tagging-mini-map__deactivate-map {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 1;

  // Do not show deactivate button in map-view mode (fullscreen)
  .map-view .map & {
    display: none;
  }
}

// We have to use important to overide element style
.gmnoprint {
  > .gm-style-mtc > div,
  > div {
    border-radius: 0 !important;
  }
}

// TODO: Consider moving this to an option when initializing the map.
.gm-iv-container {
  display: none;
}

.gm-fullscreen-control {
  cursor: pointer;
}

@media (max-width: $extra-small-size) {
  .geotagging {
    &__map {
      min-height: 66vh;
    }

    &__overlay {
      padding: 10px;
    }

    .call-to-action .btn {
      font-size: 12px;
    }
  }
}

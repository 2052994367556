$topbar-dropdown-width: 250px;
$topbar-auth-tab-width: 170px;

.topbar {
  background-color: $topbar-color;
  color: $white;
  line-height: 1.5;
  position: relative;
  padding: 0 $spacer-layout;
  padding-right: 0;
  @media (min-width: $extra-large-size) {
    padding: 0 20px;
    padding-right: 0;
  }

  .is-mobile & {
    z-index: 10;
    position: fixed;
    top: 0;
    width: 100%;
    opacity: 0.95;
  }

  &__logo {
    align-items: center;
    cursor: default; // TODO: Check why?
    display: flex;
    flex-grow: 1;
    margin-top: .5rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__logo-text {
    color: $white;
    display: inline-block;
    line-height: 1.4;
    margin: .3rem 0 0 10px;

    h1 {
      font-size: 1.9rem;
      margin: 0;
    }

    span {
      display: block;
    }
  }

  &__logo-tagline {
    font-size: 1.1rem;
    letter-spacing: .03rem;
  }

  &__logo-icon {
    display: inline-block;

    svg {
      height: 40px;
      vertical-align: middle;
      width: 40px;
    }

    &--large {
      svg {
        height: 60px;
        width: 60px;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  svg {
    fill: $white;
  }

  &__items {
    align-items: center;
    display: flex;
    height: $topbar-height;

    &-link {
      color: $white;
      background-color: transparent;
      flex-shrink: 0;
      text-decoration: none;
      padding: 12px;
      display: inline-block;
      line-height: 32px;
      border: none;

      &:focus,
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }

    .dropdown {
      &__button {
        background-color: transparent;
        border: 0;
      }
      &__option {
        padding: 12px;
      }
      &__options {
        top: $topbar-height;
        left: 0;
        min-width: $topbar-dropdown-width;
        // Make sure the dropdown options are above the button
        // in order to make the shadow look correct.
        z-index: 5;
        margin: 0;
        padding: 0;
        a {
          &:hover {
            color: $brand-primary;
          }
        }
      }
      &--active,
      &__selected {
        &::after {
          border: 0;
        }
      }
      &--active {
        background: white;
        .topbar__items-link {
          background: white;
          color: black;
          z-index: 10;
          position: relative;
          border: none;
          a {
            color: black;
          }
        }
      }
    }
  }

  &__hamburger {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 0;
    padding-left: 10px;
    padding-right: 2px;
    text-decoration: none;
    background: transparent;
    border: none;

    .is-mobile & {
      padding: 0 20px;
    }

    svg {
      height: 20px;
      margin-right: 10px;
      width: 26px;
    }

    &:focus {
      outline: 1px dotted black;
    }
  }

  &__menu {
    flex-grow: 3;
  }

  &__search {
    flex-grow: 1;

    .input-group {
      float: right;
    }

    button:focus {
      outline: 1px dotted black;
    }

    svg {
      height: 1.7rem;
      width: 1.7rem;
    }
  }

  &__name {
    margin-top: 90px;
    a {
      padding: 0;
    }
    .icon {
      svg {
        width: 14px;
        height: 14px;
        fill: white;
        float: right;
        margin-top: 8px;
        margin-right: 10px;
        transform: rotate(180deg);
      }
    }
  }

  &__auth {
    width: $topbar-auth-tab-width;
    height: $topbar-height;
    line-height: $topbar-height;
    .dropdown {
      width: 100%;
      &--active {
        svg {
          transform: rotate(0deg);
          fill: black;
        }
        .topbar__items {
          &-link {
            padding-bottom: 10px;
          }
        }
      }
      &__options {
        position: relative;
        top: -1px;
        left: -($topbar-dropdown-width - $topbar-auth-tab-width);
      }
      &__selected {
        width: 100%;
        margin-top: 0;
        position: static;
        padding: 0;
      }
    }
    .dropdown__selected::after {
      border-top-color: $white;
      right: -20px;
    }
  }
}

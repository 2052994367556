// Scroll to top button
#toTop {
  bottom  : 15px;
  display : none;
  opacity : .80;
  position: fixed;
  right   : 30px;
  z-index : 99;

  svg {
    height: 50px;
    width : 50px;
  }
}

#toTop:hover {
  cursor : pointer;
  opacity: 1;
}

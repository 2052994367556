@mixin document-section($index) {
  background-color: map-get($document-section-backgrounds, $index);
  color: map-get($document-section-colors, $index);
}

.document {
  h1,
  h2,
  h3 {
    font-family: $document-heading-font;
  }

  table {
    margin-top: .5rem;
    text-align: left;
    width: 100%;
  }

  td,
  th {
    vertical-align: top;
    word-wrap: break-word;
  }

  th {
    padding-right: 1rem;
    white-space: nowrap;
  }

  td {
    width: 100%;

    .btn {
      margin: .5rem 0;
    }
  }

  // Adding default padding to the sections
  &__section-1,
  &__section-2,
  &__section-3,
  &__section-4,
  &__section-5 {
    padding: 1rem 0 2rem;
  }

  &__section-1 {
    @include document-section(1);
    padding-bottom: 2rem;
    position: relative;
    @media (min-width: $small-size) {
      padding-top: $topbar-height;
    }
    @media (min-width: $medium-size) {
      padding-top: $topbar-height * 3 / 2;
    }
  }

  &__section-2 {
    @include document-section(2);
  }

  &__section-3 {
    @include document-section(3);
  }

  &__section-4 {
    @include document-section(4);
  }

  &__section-5 {
    @include document-section(5);
  }

  &__title {
    margin-top: 0;
  }

  &__description {
    font-size: 1.13rem;
    margin-bottom: .3rem;
    white-space: pre-line;
  }

  &__secondary-description {
    white-space: pre-line;
  }

  &__metadata {
    // Makes sure a width: 100%; will not extend this
    position: relative;
  }

  &__player {
    // height: 100%;
    display: block;
    max-height: 2000px; // Should match the size given in the image path
    max-width: 100%;
    width: 100%;
    position: relative; // Positions the navigator relative to the player
  }

  &__geo-tagging,
  &__player {
    // Remove the padding from the col-* classes on small screens
    @media (max-width: $small-size) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__player-controls {
    margin: 1rem 0;
    text-align: right;

    svg {
      fill: map-get($document-section-colors, 1); // TODO: Could we inherit?
      height: $icon-size;
      width: $icon-size;
    }

    a {
      display: inline-block;
      text-decoration: none;

      &:not(:last-child) {
        margin-right: $icon-size / 2;
      }

      svg {
        vertical-align: middle;
      }
    }
    // Left-align controls on a larger screen
    @media (min-width: $small-size) {
      text-align: left;
    }
    @media (max-width: $small-size) {
      padding: 0 1rem;
    }
  }

  &__navigator {
    &-preview {
      background: $white;
      display: none;
      margin: .5rem;
      padding: $spacer-xs;
    }

    &-preview-image {
      min-width: 100px;
      width: 5vw;
    }

    &-arrow {
      align-items: center;
      display: flex;
      opacity: 1;
      position: absolute;
      top: 50%;
      z-index: 99;
      transform: translate(0, -50%);
      transition: $anim-duration-long;

      svg {
        background: $navigator-arrow-background;
        fill: $navigator-arrow-color;
        height: 3rem;
        padding: .5rem;
        width: 3rem;
      }

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }

      &--hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
      @media (pointer: fine) {
        &:focus,
        &:hover {
          .document__navigator-preview {
            @include shadow(3);
            display: block;
          }
        }
      }
    }

    &-title {
      display: none;
    }
  }

  // Expansion
  &__expandable {
    [data-action='toggle-expandable'] {
      cursor: zoom-in;
    }

    // Show or hide the zoon-in/out buttons
    .document__player-controls {
      .zoom-in {
        display: inline-block;
      }

      .zoom-out {
        display: none;
      }
    }

    &--expanded {
      // A little top-padding on larger screens
      @media (min-width: $small-size) {
        padding-top: 1rem;
      }

      [data-action='toggle-expandable'] {
        cursor: zoom-out;
      }

      // What happens when a document expandable is expanded?
      // The collumns inside the expandable .rows become wide
      .row {
        > [class*='col-'] {
          width: 100%;
        }
      }

      .document__player-controls {
        .zoom-in {
          display: none;
        }

        .zoom-out {
          display: inline-block;
        }
      }
    }

    // Animate the expandable and the cols inside any of its rows when expanding
    @if $document-expansion-animation {
      transition: $anim-duration-fast;

      .row {
        > [class*='col-'] {
          transition: $anim-duration-fast;
        }
      }
    }
  }
}

// document-article
.document-article {
  display: flex;
  flex-direction: column;

  @media(min-width: $small-size) {
    display: grid;
    gap: 25px 82px;
    grid-template:
      "backlink media" auto
      "creation-time media" auto
      "heading media" auto
      "resume media" 1fr
      "article article" auto;
    grid-template-columns: 1fr 1fr;
  }

  // map with location underneath 3D model
  .geo-tagging-mini-map {
    margin-top: 2rem;
    height: 192px;

    @media(min-width: $small-size) {
      height: 225px;
    }
  }
}

.place-overlay__inner table {
  margin-top: .5rem;
  text-align: left;
  width: 100%;
}

.document-article__media {
  grid-area: media;

  @media (max-width: $small-size) {
    margin: 0 -15px;
  }
}

.document__asset-creation-time {
  grid-area: creation-time;
  font-size: 1.1rem;
  display: flex;
  gap: 1rem;

  @media (max-width: $small-size) {
    margin-bottom: 0.8rem;
  }
}

.document__large-model-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media(min-width: $small-size) {
    text-align: start;
  }
  @media (max-width: $small-size) {
    margin-left: 10px;
  }
}


.bur-section,
.bur-footer {
  grid-column: span 2;
}

.bur-footer {
  @media(min-width: $small-size) {
    margin-bottom: 40px;
  }
}

.document-article__backlink {
  grid-area: backlink;

  &.document__back-link {
    button {
      @media(max-width: $small-size) {
        margin: 1rem -15px;
      }
    }
  }
}

.bur-header {
  grid-area: heading;
  font-size: 2rem;
  line-height: 1.25;
  font-family: $document-heading-font;
  width: 100%;
  place-self: start;
  margin-top: 0;
  margin-bottom: 1rem;

  @media(min-width: $small-size) {
    font-size: 3.62rem;
    margin-bottom: 0;
  }
}

.bur-resume {
  grid-area: resume;
  font-size: 1.4rem;
  margin-bottom: 0.3rem;
  white-space: pre-line;
  place-self: start;
}

// Styling for articles from Alexandra Institute
.bur-article {
  font-size: 1.13rem;
}

.bur-paragraph,
.bur-imagecaption,
.bur-header,
.bur-resume {
  max-width: 650px;
}

.bur-paragraph {
  grid-area: text;
  grid-row: span 1;
}

.bur-section {
  display: grid;
  grid-template:
    "sectionheader" auto
    "text" auto
    "image" auto;
  margin-top: 2rem;
  font-size: 1.2rem;
  place-content: center;
  column-gap: 8rem;

  @media(min-width: $small-size) {
    grid-template:
      "image sectionheader" auto
      "image text" 1fr;
  }
}

.bur-section:nth-of-type(even) {
  grid-template:
    "sectionheader" auto
    "text" auto
    "image" auto;


  @media(min-width: $small-size) {
    grid-template:
      "sectionheader image" auto
      "text image" 1fr
  }
}

.bur-sectionheader {
  grid-area: sectionheader;
  font-size: 1.4rem;
  font-family: $document-heading-font;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

div.bur-video,
.bur-image {
  max-width: 750px;
}

// video container and video element got the same class name "bur-video"
div.bur-video {
  video {
    max-width: 100%;
  }
}


.bur-image {
  grid-area: image;
  img {
    max-width: 100%;
    background-color: $gray-light;
  }
}

.bur-imagecaption {
  margin-top: 8px;
  font-style: italic;
  font-size: 1rem;
}

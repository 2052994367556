$map-height: 455px;
$map-height--desktop: 480px;

.geo-tagging-mini-map {
  height: $map-height--desktop;

  @media(max-width: $small-size) {
    height: $map-height;
  }
}

// Have floated buttons seperate a bit.
.map-coordinates__map--edit .btn.btn-primary {
  margin-right: 10px;
}

.place-overlay {
  pointer-events: none;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  max-width: $extra-large-size;
  padding: 0 $spacer-md;
  left: 50%;
  transform: translateX(-50%);

  .is-mobile & {
    position: static;
    // The box is now placed underneath the map, so we can allow it to auto-fit
    // to the column it is in.
    margin-top: 0;
    min-height: auto;
    height: auto;
    transform: none;
  }
  &__inner {
    pointer-events: auto;
    background: white;
    margin-top: 100px;
    max-width: 400px;
    min-height: 300px;
    padding: $spacer-lg;
    @include shadow(5);
    .is-mobile & {
      position: static;
      margin-top: 0;
      @include shadow(0);
    }
    .btn {
      float: left;
      width: auto;
      font-size: 1rem;
    }
  }
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  table {
    th {
      width: 120px;
    }
    td {
      width: auto;
    }
    .btn {
      margin-top: 1.5rem;
    }
  }
}

/*
Should probably be in a separate file
*/
.mini-map-with-query {
  height: 45vh;
  min-height: 350px;
  background-color: #f0f0f0;
  position: relative;

  &.after-jumbo {
    @media (min-width: $small-size) {
      height: calc(45vh + 85px);
      margin-top: -85px;

      &:before {
        content: '';
        background-color: white;
        position: absolute;
        transform: skewY(-5.5deg);
        width: 100%;
        top: -30px;
        height: 61px;
        z-index: 1;
      }
    }

    @media (min-width: $medium-size) {
      height: calc(45vh + 115px);
      margin-top: -115px;

      &:before {
        top: -30px;
        height: 61px;
        z-index: 1;
      }
    }
  }
}

.motif-tagging {
  .btn-group {
    display: flex;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }

  .btn {
    margin-bottom: .5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__editing-container {
    margin-top: .5rem;
    display: none;
  }

  &__editing-container--editing {
    display: block;
  }

  &__suggestion-explanation {
    margin-bottom: .5rem;
  }

  &__add-input {
    margin-right: .5rem;

    label {
      color: #999999
    }
  }

  &__add-tag {
    margin-bottom: .5rem;
  }

  &__save-buttons {
    margin-top: 1rem;
  }

  &__cancel-btn {
    margin-left: 1rem;
  }

  &__transfer-btn,
  &__remove-btn {
    display: inline-block;

    svg {
      height: 13px;
      vertical-align: -10%;
      width: 13px;
    }
  }

  &__transfer-btn {
  }
}

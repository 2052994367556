$brand-primary--timeline-dark: #b01c51;
$brand-primary--timeline-light: #ff4e8d;

@mixin in-expanded-timeline {
  .timeline:hover &, .timeline:focus-within & {
    @content;
  }
}

.timeline-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);

  * {
    box-sizing: border-box;
  }

  @media(min-width: $small-size) {
    position: absolute;
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding-bottom: 0.25rem;
  padding-top: 0;

  @media(min-width: $small-size) {
    overflow-x: hidden;
    padding-top: 15px;
    margin-right: 40px;
    margin-left: 40px;
  }
}

.timeline__points {
  order: 2;
  display: flex;
  flex-direction: row;
}

.timeline__point {
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  font-weight: bold;
  font-size: 0.75rem;
  padding-top: 0.55rem;
  padding-bottom: 0.125rem;
  position: relative;

  &::before {
    content: '';
    border: 3px solid #fff;
    border-radius: 50%;
    background-color: $gray;
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: 50%;
    top: -12px;
    transform: translateX(-50%);
    z-index: 3;
    transition: background-color 0.2s, height 0.2s;
  }
}
.timeline__point--selected {
  &::before {
    background-color: $brand-primary;
  }
}
.timeline__point--hovered {
  &::before {
    background-color: $brand-primary;
  }
}

.timeline__point-label {
  display: inline;
  padding: 0.125rem;
  color: $gray-darker;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s;
  white-space: nowrap;

  .timeline__point--selected & {
    color: $brand-primary--timeline-dark;

    @include in-expanded-timeline {
      color: $brand-primary--timeline-light;
    }
  }

  .timeline__point--hovered & {
    @include in-expanded-timeline {
      color: $brand-primary--timeline-dark;
    }
  }
}

.timeline__periods {
  order: 1;
  display: flex;
  flex-direction: row;
}

.timeline__spacer {
  width: 50px;
  flex-shrink: 0;
  flex-grow: 0;
}

.timeline__period {
  display: block;
  background: none;
  border: none;
  font-family: inherit;
  position: relative;
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  transition: color 0.2s, background-color 0.2s, height 0.2s, padding-bottom 0.2s;
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 2.35rem; /* 1.35 rem padding + 1rem text height */
  padding-bottom: 0.5rem;


  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    background: $gray;
    border-top: 2px solid $gray;
    transition: border-top-color 0.4s;
    z-index: 1;
  }

  /*
    wrapping in timeline hover state is only here to make rules most specific
    if a period is hovered, the timeline is always hovered
  */
  @include in-expanded-timeline {
    &:hover:not(:disabled) {
      color: $brand-primary--timeline-dark;

      &::after {
        border-top-color: $brand-primary;
      }
    }
  }
}

.timeline__period--selected {
  @include in-expanded-timeline {
    color: $brand-primary--timeline-dark;
  }

  &::after {
    border-top-color: $brand-primary;

    @include in-expanded-timeline {
      border-top-color: $brand-primary;
    }
  }
}

.timeline__period--no-results {
  color: $gray-darker;
  cursor: pointer;

  &::after {
    background: transparent;
    border-top-color: rgb(200, 200, 200);
    border-top-style: dashed;
  }

  &:not(:disabled) {
    &::before {
      content: 'Ryd filtre';
      display: block;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      transition: opacity 0.35s;
      background: rgb(245,245,245);
      z-index: 3;
      padding: 5px;
      box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    }

    &:hover::before {
      opacity: 1;
    }

    @include in-expanded-timeline {
      &.timeline__period--selected {
        &::before {
          content: 'Ingen resultater';
          opacity: 1;
        }
      }
    }
  }
}

.timeline__period-label {
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.timeline__period-result-size {
  display: none;

  @media(min-width: $small-size) {
    display: block;
    background: $gray-light;
    position: absolute;
    width: 96%;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);

    .timeline__period--selected & {
      background: linear-gradient(to bottom, $brand-primary--timeline-light, $white 100%);
    }
  }
}

.timeline-modal {
  z-index: 100;
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  border-radius: 2px;
  border: none;
  padding: 1rem;
  max-width: 560px;
  width: 100%;
}

.timeline-modal__footer {
  display: flex;
  justify-content: space-between;
}

.timeline__navigation {
  display: none;
  z-index: 4;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  // Styling mimics the look from Slick-slider
  height: 38px;
  width: 38px;
  border-radius: 48px;
  opacity: 1;
  background-color: $white;
  background-size: 16px;
  content: '';
  box-shadow: 0 3px 12px rgba(0,0,0,.08), 0 10px 40px rgba(0,0,0,.06);
  background-repeat: no-repeat;
  background-position: center;
  border: none;

  @media(min-width: $small-size) {
    display: block;
    height: 48px;
    width: 48px;
  }

  &:hover {
    background-color: $brand-primary;
    background-size: 16px;
  }
}

.timeline__navigation--left {
  left: 10px;
  background-image: url(../../images/icons/arrow-thin-left.png);

  &:hover {
    background-image: url(../../images/icons/arrow-thin-left-hover.png);
  }
}

.timeline__navigation--right {
  right: 10px;
  background-image: url(../../images/icons/arrow-thin-right.png);

  &:hover {
    background-image: url(../../images/icons/arrow-thin-right-hover.png);
  }
}

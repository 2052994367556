.frontpage {
  display  : flex;
  flex-wrap: wrap;
  margin   : 0 -15px;
  padding  : 3.3rem 0 2.7rem;
}

.frontpage__catalog {
  padding: 10px;
  width  : 50%;

  @media (pointer:fine) {
    &:hover,
    &:focus {
      @include shadow(3);
      z-index: 1;
    }
  }

  a {
    color          : $black;
    text-decoration: none;
  }

  h2 {
    margin    : 1.1rem 0 0;
    word-break: break-word;
  }

  @media (min-width: $small-size) {
    padding: 15px;
  }

  @media (min-width: $medium-size) {
    width  : 33.33333%;

    h2 {
      margin-left: -1px;
    }
  }
}

.frontpage-galleries {
  padding-bottom: 2rem;
  padding-top: 0rem;

  @media (min-width: $large-size) {
    padding-bottom: 3rem;
    padding-top: 0rem;
  }
}

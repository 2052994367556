.site-wide-message {
  position: relative;
  padding: 15px 32px;
  margin-left: -20px;
  background-color: $topbar-color;
  border-bottom: 1px solid;
  border-color: $black;
}

.site-wide-message__link {
  color: inherit;
  background: $brand-primary;
  padding: 0 0.25rem;
  margin: 0 0.25rem;
  white-space: nowrap;

  &:hover {
    color: white;
  }
}

.site-wide-message__title {
  font-weight: bold;
}

.site-wide-message__description {
  margin: 0;
  text-align: center;
  line-height: 1.5rem;
}

.site-wide-message__close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

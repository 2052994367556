.sidebar-menu-open {
  left    : 0;
  position: fixed;
  right   : 0;
  top     : 0;

  .gray-overlay {
    cursor        : pointer;
    opacity       : 1;
    pointer-events: all;
    visibility    : visible;
  }

  .sidebar-menu {
    display: block;
    box-shadow: 0 0 1px $menu-shadow, 0 0 3px $menu-shadow, 0 0 10px $menu-shadow;
    padding-left: 0;
    right     : 0;
    transition: right $anim-entrance $anim-duration;

    a {
      display: block;
    }
  }
}

.gray-overlay {
  background    : rgba($black, .2);
  bottom        : 0;
  left          : 0;
  opacity       : 0;
  pointer-events: none;
  position      : absolute;
  right         : 0;
  text-align    : left;
  top           : 0;
  transition    : opacity $anim-duration ease $anim-duration;
  visibility    : hidden;
  z-index       : 9998;

  &::before {
    background    : rgba($black, .4);
    height        : 100%;
    mix-blend-mode: soft-light;
    width         : 100%;
  }
}

// Menu styling
$expand-width: 50px;

.sidebar-menu {
  background: $topbar-color;
  box-shadow: 0 0 1px $menu-no-shadow, 0 0 3px $menu-no-shadow, 0 0 10px $menu-no-shadow;
  height    : 100%;
  overflow-y: auto;
  position  : fixed;
  right     : -$menu-width;
  top       : 0;
  transition: right $anim-exit $anim-duration;
  width     : $menu-width;
  z-index   : 9999;
  padding-top: 10px;

  @media (min-width: $small-size) {
    right: -$menu-width-small;
    width: $menu-width-small;
  }

  .sidebar-menu__link-seperator {
    width: 95%;
    height: 1px;
    display: block;
    margin: 0 auto;
    background-color: lighten($topbar-color, 5%);
  }

  a {
    color: $white;
    display: none;
    float: none;
    padding: 10px 15px;
    text-decoration: none;
    width: 100%;

    &:hover,
    &:focus {
      // Has to be important to override bootstrap without too much nesting
      // scss-lint:disable ImportantRule
      background-color: $menu-highlight !important;
    }
  }
}

@media (pointer:coarse) {
  .sidebar-menu {
    a:hover,
    a:focus {
      background-color: transparent;

      > span {
        color: inherit;
      }
    }

    a {
      -webkit-tap-highlight-color: $menu-highlight;
    }
  }
}
